<template>
  <section class="section container">
    <div class="columns is-centered box">
      <div class="column is-6-desktop is-5-widescreen">
        <form v-on:submit.prevent="login">
          <b-field>
            <b-input placeholder="Login" type="text" v-model="username" required></b-input>
          </b-field>

          <b-field>
            <b-input placeholder="Senha" type="password" v-model="password" required></b-input>
          </b-field>

          <button class="button is-info is-fullwidth">Entrar</button>

          <router-link class="button is-text m-t-sm" to="/register">Criar cadastro</router-link>
          <router-link
            class="button is-text m-t-sm button-custom"
            :to="{name: 'Recuperar senha', params:{name: 'false'}}"
          >Esqueci minha senha</router-link>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";
import { setTimeout } from 'timers';

export default {
  data() {
    return {
      username: null,
      password: null,
      go: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name !== "Login" && from.name !== "Recuperar senha" && from.name !== "Assban" && from.name !== "Exame") {
        vm.go = from.path;
      }
    });
  },
  methods: {
    login() {
      Axios.post("/v1/auth", {
        username: this.username,
        password: this.password
      })
        .then(data => {
          if (data.data) {
            this.$store.state.user = data.data;
            this.$store.commit("setUser", data.data);
            this.getMe();
          }
        })
        .catch(erro => {
          console.error(erro);

          this.$buefy.dialog.alert({
            title: "OPPS!",
            message: "Usuário ou senha errados! <br> Login: " + this.username,
            type: "is-danger",
            hasIcon: true,
            iconPack: "fa"
          });
        });
    },
    getMe() {
      Axios.get("/v1/users/me")
        .then(data => {
          if (data.data) {
            let profileName =
              data.data.roles && data.data.roles[0]
                ? data.data.roles[0]
                : data.data.roles;
            localStorage.setItem("profile", profileName);
            this.$router.push({ path: this.go  ? this.go : "/user/cursos" });
            
            setTimeout(() => {
              location.reload(true);
            }, 1000);
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

section {
  height: 100%;

  .box {
    height: 100%;
    align-items: center;
    padding: 8%;
    @include tablet() {
      padding: 6%;
    }
    @include mobile() {
      padding: 5%;
    }
    form {
      width: 100%;
    }
  }
  .button-custom {
    float: right;
  }
}
</style>
